import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import { getCookeiDetail } from "../utils/helper";
import ApiServices from "../utils/ApiServices";
import { useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import { PencilSquare, Heart, HeartFill, ArrowLeft, PencilFill } from 'react-bootstrap-icons';
import { getUserToken } from "../utils/helper";



const Feed = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isAvailable, setIsAvailable] = useState(false);
  const [postData, setPostData] = useState();
  const [comment, setComment] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [replyAll, setReplyAll] = useState([]);
  const [mode, setMode] = useState('');
  const data = getCookeiDetail("astrologer_data")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({});
  const [uploadMedia, setUploadMedia] = useState('')
  const [filePreview, setFilePreview] = useState(null);
  const [fileURL, setFileURL] = useState("");
  const [downloadURL, setDownloadURL] = useState();
  const [isImageLoaded, setIsImageLoaded] = useState(false);


  const validFileTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  useEffect(() => {
    const fetchData = async () => {
      if (!params) navigate(`/`, { replace: true });
      let payload = {
        "postId": params.feedid,
        // "commentPage": 1,
        // "commentLimit": 10
      }
      const res = await ApiServices.post(`astrologer/quest/detail`, payload, {
        Authorization: getUserToken()
      });
      if (res.status == 200 && res.data.status == true) {
        setPostData(res.data.data)
        const Data = res.data.data[0];
        let answerMedia = res.data.data?.answerMedia
        if (answerMedia) {
          setDownloadURL(answerMedia)
          if (answerMedia.match(/\.(jpeg|jpg|png)$/i)) {
            setFileURL(answerMedia)
          }
          if (answerMedia.match(/\.pdf$/i)) {
            setFileURL("../images/pdf_default.png");
          }
          if (answerMedia.match(/\.(doc|docx)$/i)) {
            setFileURL("../images/word_default.png");
          }
        }
        if (Data?.availability == 2) {
          setIsAvailable(false)
        } else {
          setIsAvailable(true)
        }
      } else {
        console.log("Error:", res);
      }
    }
    fetchData()
  }, [refresh])

  const validateInputs = () => {
    let isValid = false;

    const newErrors = {};

    if (!comment || comment === "")
      newErrors.comment = "Please write answer.";

    return newErrors;
  };
  // const uploadFile = e => {
  //   if (e.target.files.length !== 0) {
  //     setUploadMedia(e?.target?.files[0]);
  //   }
  // }

  const uploadFile = e => {
    const file = e.target.files[0];
    if (file) {
      if (!validFileTypes.includes(file.type)) {
        addToast("Invalid file type", { appearance: 'error' });
        setFilePreview(null);
        setUploadMedia(null);
        return;
      }
      setUploadMedia(file);

      // Check if it's an image file and create a preview
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result); // Set preview for image files
        };
        reader.readAsDataURL(file); // This will create a preview URL for image
      } else if (file.type === "application/pdf") {
        // Show default PDF icon
        setFilePreview("../images/pdf_default.png");
      } else if (file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        // Show default DOC/DOCX icon
        setFilePreview("../images/word_default.png");
      } else {
        // If file is not an image or document, you can handle other file types
        setFilePreview("../images/pdf_default.png"); // Default icon for unknown file types
      }
    }
  };

  const addAnswer = async () => {
    const newErrors = validateInputs();
    setLoading(true);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
    } else {
      // Create a FormData object to handle both the text and media file
      let formData = new FormData();
      formData.append("postId", params.feedid);
      formData.append("answer", comment);

      // If there is a media file, append it to the formData
      if (uploadMedia) {
        formData.append("TrilokAnswerMedia", uploadMedia);
      }

      try {
        const res = await ApiServices.post(
          "astrologer/quest/add_answer",
          formData,
          {
            headers: {
              "Authorization": getUserToken(),
              "Content-Type": "multipart/form-data" // Make sure the header is set for multipart form data
            }
          }
        );

        if (res.status == 200 && res.data.status == true) {
          addToast(res?.data?.message, { appearance: 'success' });
          setComment('');
          setUploadMedia(null);  // Clear the uploaded media after successful upload
          setRefresh(!refresh);
          setLoading(false);
        } else {
          console.log("Error:", res);
          setLoading(false);
        }

      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    }
  }

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    console.log(key);

    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }

  const handleReply = (repData) => {
    document.getElementById('comments').focus();
    setMode(repData);
  }

  const showAllReply = async (data) => {
    let payload = {
      "postId": data?.postId,
      "commentId": data?._id
    }
    const res = await ApiServices.post(`astrologer/post/reply_list`, payload, {
      Authorization: getUserToken()
    });
    if (res.status == 200 && res.data.status == true) {
      setReplyAll(res?.data?.list[0]);
      setExpanded(true)
    } else {
      console.log("Error:", res);
    }
  }

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper astrologer_feed_area">
        <div className="astrologer_heading">
          <div className="container d-flex justify-content-between">
            <h4>
              <ArrowLeft
                className="cursor-pointer"
                style={{ marginRight: "15px" }}
                size={25}
                onClick={() => navigate(-1)} />
              Question/Answer</h4>
            <div className="d-flex align-items-center flex-column flex-lg-row flex-md-row">
            </div>
          </div>
        </div>
        <div className="astrologer_feed_inner mt-3 pb-5">
          <div className="container">
            <div className="row">
              <div className="">
                <div className="astro_feed_sec">
                  <div className="card _feed_box mt-3">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="user_info d-flex align-items-center">
                        <img src={postData?.userId?.profileImage || '../images/chat_UserImage.jpg'} className="img-fluid user_img" />
                        <div className="ms-2">
                          <h4>{postData?.userId?.name}</h4>
                          <p>{moment(postData?.createdAt).format('MMM Do YYYY, h:mm A')}</p>
                        </div>
                      </div>
                      <div className="fw-medium ">
                        <span className=" text-capitalize me-2">{postData?.utmSource}</span>
                        <span>
                          {postData?.currency === "INR" ? `₹${postData?.amount}` : `$${postData?.amount}`}
                        </span>
                      </div>
                      {/* <a href="#"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></a> */}
                    </div>
                    <div className="card-body">
                      <div className="detail-card">
                        <ul className="nav question-item">
                          <li className="me-4">
                            {postData?.additionalDetails?.name}
                          </li>
                          <li className="me-4">
                            Date of birth : {moment.utc(postData?.additionalDetails?.dob).format('ll')}
                          </li>
                          <li className="me-4">
                            Birth Time : {moment.utc({ hour: postData?.additionalDetails?.hour, minute: postData?.additionalDetails?.min }).format('hh:mm A')}

                          </li>
                          <li className="me-4">
                            Gender : {postData?.additionalDetails?.gender}
                          </li>
                          <li className="">
                            Address : {postData?.additionalDetails?.address}
                          </li>
                        </ul>
                      </div>
                      <p className="text-danger">Question :</p>
                      <p>{postData?.description}</p>
                      <img src={postData?.mediaFile} className="img-fluid" />
                    </div>
                    <div className="card-footer">
                      <div className="feed_comments_sec">
                        <ul>
                          <li>
                            {postData?.status === "Answered" ? <>
                              {/* <div className="card-body"> */}
                              <p className="text-success">Answer :</p>
                              <p style={{ color: '#767c90' }}>{moment(postData?.answerDate).format('MMM Do YYYY, h:mm A')}</p>
                              <p>{postData?.answer}</p>
                              <div className="position-relative d-inline-block" >
                              {fileURL ? <img src={fileURL} className="img-fluid" onLoad={handleImageLoad} /> : "" }
                              {isImageLoaded && (
                                  <a href={downloadURL} target="_blank" download={downloadURL} className="d_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                      <path fill="currentColor" d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
                                    </svg>
                                  </a>
                                )}
                              
                              </div>
                            </> : <>
                              <div className="">
                                <textarea className="form-control" name="comment" type="text" placeholder={`Write Answer...`} value={comment} onChange={e => { setComment(e.target.value); handleChange(e) }} id="comments" />
                                <span className="errorVal"> {errors?.comment}</span>
                              </div>
                              <div className="preview_wrapper">
                                <div className="input-wrapper">
                                  <input
                                    name='answerMedia'
                                    type='file'
                                    id='imageUpload'
                                    accept='.png, .jpg, .jpeg, .pdf, .doc, .docx'
                                    onChange={uploadFile}
                                  />
                                  <p><PencilFill /> Choose your file</p>
                                </div>
                                {filePreview && (
                                  <div className="file-preview">
                                    {filePreview && filePreview.startsWith("data:image") ? (
                                      <img src={filePreview} alt="File Preview" className="preview-img" />
                                    ) : (
                                      <img src={filePreview} alt="File Icon" className="preview-icon" />
                                    )}
                                  </div>
                                )}
                              </div>
                              {loading ? <button className="as_btn mt-2 ms-0" >Processing...</button> : <button className="as_btn mt-2 ms-0" onClick={(e) => { addAnswer(); }}>Submit</button>}

                            </>}

                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="feeds_requset_sec mt-3">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Feed;